import { useFrame, useThree } from "@react-three/fiber";
import {  useEffect, useState } from "react";

import { useDetectGPU } from "@react-three/drei";
function CameraViewController(){

    const {camera} = useThree();

    const tre = useThree();
    const gpu = useDetectGPU();

    function resetResolution(ray, tre){
      ray.splice(0, 30);
      const arrAvg = arr => arr.reduce((a,b) => a + b, 0) / arr.length

      let avg = arrAvg(ray);

      //console.log( 10 * (0.019 - avg ) );
      let fps = 1000 / (avg * 1000);
      //console.log(fps)
      //console.log( 1.0 - (5 * 0.0055 ) )
      if (fps >= 70){
        //console.log(window.devicePixelRatio)
        //console.log('below!')
        //console.log( 1.0 - ((60 - fps) * 0.0055 ) )
        //console.log(window.devicePixelRatio - ( window.devicePixelRatio * ((60 - fps) * 0.0235) ) );
        //tre.setDpr(window.devicePixelRatio - ( window.devicePixelRatio * ((60 - fps) * 0.0235) ) );
        //tre.setDpr(1);
        //console.log('switch!');
      }

      
    }

    const [runningSpeedTest, setRunningSpeedTest] = useState(true);
    let index = 0;
    let test_length = 90;
    let avg_ray = []
    useFrame((state, delta) => {
      if (runningSpeedTest){
        avg_ray.push( delta )
        index++;

        if (index >= test_length){
          resetResolution(avg_ray, tre);
          setRunningSpeedTest(false);
        }
      }
    })

    useEffect(() => {

      //console.log(gpu)
      setTimeout(() => {
        //tre.setDpr(2);
      }, 10000)


      function resizeHandler(){
        let ratio = window.innerWidth / window.innerHeight;
        
        //console.log(ratio);

        if (ratio < 0.84){
            camera.fov = 90;
            camera.position.set(7 * 0.75, 5 * 0.75, 20 * 0.75);
        }

        if (ratio <  1.1  && ratio >= 0.84){

          
        camera.fov = 90;
        camera.position.set(7 * 0.75, 5 * 0.75, 20 * 0.75);
          

 
        }

        /*

        if (ratio >= 1.1 && ratio < 1.44 ) {
            camera.fov = 80;
            camera.position.set(7 * 0.65, 6 * 0.65, 20 * 0.65);
        }
        */

        //tiny mode 0.6, fov 120? medium is 0.7 fov 60, ultra wide is 1.0 fov 40
        if (ratio >= 1.11 && ratio <= 2.25 ){

          camera.fov = 60;
          camera.position.set(7 * 0.7, 6 * 0.7, 20 * 0.7)

          //console.log(camera.position);
        }

        if (ratio > 2.25 ){

          camera.fov = 40;
          camera.position.set(7, 4, 20);

          //console.log(camera.position);


        }
      }

      resizeHandler();
  
      window.addEventListener('resize', resizeHandler);
  
      return () => {
        window.removeEventListener('resize', resizeHandler)
      }
    }, [camera]);

    return(
        <>
    </>
    )
}

export default CameraViewController