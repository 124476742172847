import { useFrame, useLoader, useThree } from '@react-three/fiber';
import React, { useCallback, useEffect } from 'react'
import {useMemo, useRef} from 'react';
import { shaderMaterial } from "@react-three/drei";
import vertexShader from './vertexShader.glsl';
import fragmentShader from './fragmentShader.glsl';
import vertexShader2 from './vertexShader2.glsl';
import vertexShader3 from './vertexShader3.glsl';
import 'glsl-noise/simplex/3d.glsl'
import * as THREE from 'three';

const OceanParticles = (props) => {
    const seed = Math.floor(Math.random() * (2**16))
    const P_COUNT = 1000;
    const BASE_SEED = 23;
    const WIDTH = 200;
    const HEIGHT = WIDTH / 2;
    const DEPTH = WIDTH;
    const MIN_SIZE = 0.2;
    const SIZE_MUL = 3;
    const {gl, camera} = useThree();
    useEffect(() => {
      //console.log(gl);
    }, [])
    //const imgTex = useLoader(THREE.TextureLoader, process.env.PUBLIC_URL + '/circle.png');
    const bufferRef = useRef();
    let positions = useMemo(() => {
        let positions = [];
        for (let i = 0; i < P_COUNT; i++){
            //let x = (Math.random() * x_width) - min_x;
            //let x = Math.random() * 100 - 50;
            let x = ( Math.random() - 0.5 ) * WIDTH;
            let y = ( Math.random() - 0.05 ) * HEIGHT;
            let z = ( Math.random() - 0.1 ) * DEPTH;
            positions.push(x,y,z);
        }

        //console.log( Math.min(...positions), Math.max(...positions) )
        return new Float32Array(positions);
    });


    let t = 0;
    let lastTerrainUpdate = 0;
    let rate = 0.02;
    useFrame((state, delta) =>{
        //matRef.current.uniforms.uTime.value = state.clock.elapsedTime;

        if (state.clock.elapsedTime > lastTerrainUpdate + rate){

          lastTerrainUpdate = state.clock.elapsedTime;
          t += 0.1;
          matRef.current.uniforms.uTime.value = t;
        }

        //matRef.current.uniforms.uCameraPos.value = camera.position;
        //console.log(matRef.current.uniforms.uTime.value);
        //console.log(JSON.stringify(camera));
    });
    const geom = useRef();
    const matRef = useRef();

    //const noise_texture = useLoader(THREE.TextureLoader, '/textures/Noise/noise3.png');

    const uniforms = useMemo(() => ({
        uTime: {
          value: 0.0
        },
        uHeight: {
          value: HEIGHT
        },
        uRadius: {
          value: 0.000001
        },
        uCameraPos: {
          value: 1.0
        },
        uDPR: {
          value: props.dpr
        },

        uTimeMult: {
          value: 10
      },
      }), [])



  return (
    <points>
        <bufferGeometry attach='geometry'>
            <bufferAttribute
            ref={bufferRef}
            attach='attributes-position'
            array={positions}
            count={positions.length / 3}
            itemSize={3}
            />
        </bufferGeometry>
        <shaderMaterial
            ref={matRef}
            depthWrite={false}
            fragmentShader={fragmentShader}
            vertexShader={vertexShader3}
            uniforms={uniforms}
            alphaTest={true}
            transparent={true}
        />
        {/*<pointsMaterial/>*/}
        {/*
        <pointsMaterial attach='material'
        map={imgTex}
        color={0x00aa22}
        size={0.2}
        sizeAttenuation
        transparent={false}
        alphaTest={0.5}
        opacity={0.5}
        fog={true}
        />
        */}
    </points>
  )
}

export default OceanParticles