/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, forwardRef } from 'react'
import { useGLTF, useTexture} from '@react-three/drei'
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
import { useEffect } from 'react';
//import vertex from './bulbShader/vertex.glsl';
import fragment from './bulbShader/fragment.glsl';
//import BubbleEmitter from '../BubbleEmitter.jsx';
//export default function Vehicle_JSX({ ...props }) {
const Vehicle_JSX = forwardRef(({ ...props}, ref ) => {

  //const rotationRef = initRotationRef(20,10, 15);//body, fin, rudder

  const group = useRef()

  //const { nodes} = useGLTF('/Aston_Fish/Aston_Fish_BAKE_JANUARY_6_2023.glb')
  const {nodes} = useGLTF('/Aston_Fish/Aston_Fish_BAKE_2023_transformed-transformed.glb')

  const [bodyColorMap, interiorColorMap] = useTexture(['/Aston_Fish/body_diffuse_FINAL_GREEN.jpg', '/Aston_Fish/interior_details_diffuse.jpg']);
  const [bodyRoughnessMap, interiorRoughnessMap] = useTexture(['/Aston_Fish/body_roughness.jpg', '/Aston_Fish/interior_details_roughness.jpg']);

  bodyColorMap.flipY = false;
  interiorColorMap.flipY = false;
  bodyRoughnessMap.flipY = false;
  interiorRoughnessMap.flipY = false;
  
  const window_mat = new THREE.MeshStandardMaterial({//materials.LOW_windowmat
    transparent: true,
    opacity: 0.35,
    roughness: 0.1,
    color: '#ffffbb',
  });

  const reflective_mat = new THREE.MeshLambertMaterial({
    emissive: '#777733',
    map: bodyColorMap
  });

  const bulb_mat = new THREE.ShaderMaterial({
    uniforms: {
      uTime: {
        value: 1.0
      }
    },
    fragmentShader: fragment
  })


  const body_mat = new THREE.MeshStandardMaterial({
    map: bodyColorMap,
    //roughness: bodyRoughnessMap
  });

  const backcap_mat = new THREE.MeshStandardMaterial({
    map: bodyColorMap,
    side: 2
  });

  const interior_mat = new THREE.MeshLambertMaterial({
    map: interiorColorMap,
    //roughness: interiorRoughnessMap
  });

  useEffect(() => {
    bodyColorMap.flipY = false;
  }, [bodyColorMap]);

  useEffect(() => {
    interiorColorMap.flipY = false;
  }, [interiorColorMap]);

  useEffect(() => {
    bodyRoughnessMap.flipY = false;
  }, [bodyRoughnessMap]);

/*
  useEffect(() => {
    rotationDataInit(20,20,20, 0);
  }, [])
*/

  useEffect(() => {
    //console.log(group.current)
  }, []);

  function clampRotations(rotations, maximums){
    let tmp = 0;
    for (let i = 0; i < rotations.length; i++){
      tmp = Math.abs(rotations[i]);
      if (tmp > maximums[i]){
        tmp = maximums[i];
      }
      if (rotations[i] < 0){
        tmp *= -1;
      }
      rotations[i] = tmp;
    }
    return rotations;
  }

  const rotations = {
    ray: [
      0,0,0,0,0
    ]
  }
  const maximums = [
    8,
    4,
    5,
    10,
    10
  ]
  useFrame((state, delta) => {
    //console.log(ref.current.rotations.bodyX);
    if (ref.current.rotations !== undefined){
      rotations.ray = [
        ref.current.rotations.bodyX,
        ref.current.rotations.bodyZ,
        ref.current.rotations.bodyY,
        ref.current.rotations.fin,
        ref.current.rotations.rudder
      ]
      rotations.ray = clampRotations(rotations.ray, maximums);
      //group.current.rotation.z = ref.current.rotations.bodyX / -30;
      group.current.rotation.z = rotations.ray[0] / -30;
      //group.current.rotation.y = ref.current.rotations.bodyZ / -40;
      group.current.rotation.y = rotations.ray[1] / -50;
      //group.current.rotation.x = ref.current.rotations.bodyY / -20;
      group.current.rotation.x = rotations.ray[2] / -30;

      group.current.children[0].children[0].rotation.x = (rotations.ray[3] / 20) + (ref.current.rotations.bodyY / -15);//front left fins
      group.current.children[0].children[2].rotation.x = (rotations.ray[3] / -20) + (ref.current.rotations.bodyY / -15);//front right fin

      group.current.children[0].children[1].children[0].rotation.x = (rotations.ray[3] / 30) + (ref.current.rotations.bodyY / -25);//back left fin
      group.current.children[0].children[1].children[2].rotation.x = (rotations.ray[3] / -30) + (ref.current.rotations.bodyY / -25);;//back left fin

      group.current.children[0].children[1].children[1].children[0].children[0].rotation.x = ref.current.rotations.rudder / 20;//back left rudder
      group.current.children[0].children[1].children[1].children[1].children[0].rotation.x = ref.current.rotations.rudder / -20;//back right rudder
    }
    
    //updateRotations(rotationRef, ref);
    //console.log(ref.current.distanceToMouse);
    //console.log(nodes)
    //console.log(nodes.Armature.position.x = Math.sin(state.clock.elapsedTime));
    //bodyDiffuse.rotation = state.clock.elapsedTime;
    //props.test + 1;
    //group.current.position.z = -12.0;
    //group.current.position.x = 5.0 * Math.sin(state.clock.elapsedTime)
    //group.current.rotation.z = 0.1 * Math.sin(state.clock.elapsedTime * 10.0);
    //group.current.rotation.x = 0.5 * Math.sin(state.clock.elapsedTime * 2.0);

    //group.current.children[0].children[0].rotation.x = Math.sin(state.clock.elapsedTime) * 0.5;//front left fin
    //group.current.children[0].children[2].rotation.x = Math.sin(state.clock.elapsedTime) * 0.5;//front right fin
    //group.current.children[0].children[1].children[0].rotation.x = Math.sin(state.clock.elapsedTime) * 0.5 ;//back left fin
    //group.current.children[0].children[1].children[2].rotation.x = Math.sin(state.clock.elapsedTime) * 0.5 ;//back right fin
    //group.current.children[0].children[1].children[1].children[0].children[0].rotation.x = Math.sin(state.clock.elapsedTime) * 0.5;//back left rudder
    //group.current.children[0].children[1].children[1].children[1].children[0].rotation.x = Math.sin(state.clock.elapsedTime) * 0.5;//back right rudder


    //console.log(ref.current.directionalDot);
    //console.log(ref.current.positionalDirectionNormalized);
    //console.log(ref.current.distanceToMouse);

  });

  return (
    <group ref={ref}>
      <group ref={group} {...props} dispose={null} visible={true}>
        <primitive object={nodes.Bone} />
        <primitive object={nodes.neutral_bone} />
        <skinnedMesh geometry={nodes.Plane005.geometry} material={interior_mat} skeleton={nodes.Plane005.skeleton} />
        
        <mesh geometry={nodes.Plane003.geometry} material={body_mat} />
        <mesh geometry={nodes.Plane003_2.geometry} material={backcap_mat} />
        <mesh geometry={nodes.Plane003_1.geometry} material={window_mat} />
        <mesh geometry={nodes.SKIRT001.geometry} material={nodes.SKIRT001.material} />
        <mesh geometry={nodes.Plane003_3.geometry} material={reflective_mat} />

        <mesh geometry={nodes.Plane.geometry} material={window_mat} />
        <mesh geometry={nodes.Plane_1.geometry} material={bulb_mat} />
        <mesh geometry={nodes.Plane_2.geometry} material={reflective_mat} />
      </group>

      {/*<BubbleEmitter ref={ref}/>*/}
    </group>

  )
  });


useGLTF.preload('/Aston_Fish/Aston_Fish_BAKE_2023_transformed-transformed.glb');
export default Vehicle_JSX;
