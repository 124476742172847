import React from "react"
import {useRef, useEffect, useState} from 'react';
import {useFrame, useThree} from '@react-three/fiber';
import { useGLTF, useAnimations, Text} from '@react-three/drei';
import Vehicle_JSX from "./Models/Aston_Fish_BAKE_November";
import * as THREE from 'three';
import {vec3} from 'gl-matrix';
import {updateRotations, initRotationRef} from './Rotation.js';


import {AstonData, setDataFromRefs, updateMouseToVesselVector, 
    updateNormalized, updateDots, applyDrag, applyThrust,
    applyThrustOrbit, applyPhysics, applyMaxVelocity, setRefsFromData, updateDistances} from './PhysicsCustom2.js';
    
const AstonFish = (props) => {

    //const rotationRef = initRotationRef(10, 10, 15);
    const rotationRef = useRef(initRotationRef(10, 10, 15));
    const {raycaster, pointer, camera} = useThree();

    const planeRef = useRef();
    
    const lastMousePos2DRef = useRef([0.0, 0.0]);
    const mousePos2DRef = useRef([0.0,0.0]);
    const mousePos3DRef = useRef({point: {x: 0, y: 0, z: 0}});

    const pointerRef = useRef([0.0, 0.0, 0.0]);//pointer in 3D space
    const posRef = useRef();//ref to sphere object, including position
    const mvRef = useRef([0.0,0.0,0.0]);//ref to the velocity the craft is moving in (mv === mass velocity)
    const m3DRef = useRef([0.0,0.0,0.0]);

    const m3DIndexRef = useRef(0);
    const averageM3DRef = useRef([]);

    const meshRef = useRef();

    const SPEEDSCALE = 0.7;

    function initRefRay(count){
        let ray = [];
        for (let i = 0; i < count; i++){
            ray.push([0.0,0.0,0.0])
        }
        averageM3DRef.current = ray;
    }

    function updateRefRay(e){
        
        let ray = [];
        averageM3DRef.current.forEach((value) => {
            ray.push(value);
        })
        ray.push([e.point.x, e.point.y, e.point.z]);
        ray.shift();
        averageM3DRef.current = ray;
    }


    function averageRefRay(){
        let x = 0;
        let y = 0;
        let z = 0;
        let i = 0;
        averageM3DRef.current.forEach((value) => {
            x += value[0];
            y += value[1];
            z += value[2];
            i++;
        })
        x /= i;
        y /= i;
        z /= i;

        return [x, y, z]
    }

    useEffect(() => {

        props.handleDoneLoading(1);

        const timer = setTimeout(() => {
            props.handleDoneLoading(2)
        }, 500);


        return () => clearTimeout(timer);
    }, [])

    function receiveMouseRef(){
        let [x, y] = props.readPointer();
        //console.log(x + ", " + y)

        lastMousePos2DRef.current = mousePos2DRef.current;
        mousePos2DRef.current = [x, y];
        //console.log(mousePos2DRef.current);
    }
    //add noise functions??
    function updateMousePosClient(raycaster, camera, planeRef){
        receiveMouseRef();
        //raycaster.setFromCamera({x: y:}, camera);
        if (mousePos2DRef.current[0] === lastMousePos2DRef.current[0] && mousePos2DRef.current[1] === lastMousePos2DRef.current[1]){
            updateRefRay(mousePos3DRef.current);
            m3DRef.current = averageRefRay();
            //mousePos3DRef.current = {point: {x: m3DRef.current[0], y: m3DRef.current[1], z: m3DRef.current[2]}}
        } else if ( props.menuActive && mousePos2DRef.current[1] >= 0.8   || !props.menuActive && mousePos2DRef.current[0] > 0.5 || props.aboutActive && mousePos2DRef.current[0] < 0.0){
            
            updateRefRay({point: {x: 0, y: 0, z:0}});
            m3DRef.current = averageRefRay();
            mousePos3DRef.current = {point: {x: 0, y: 0, z:0}}
        } else {
            raycaster.setFromCamera({x: mousePos2DRef.current[0], y: mousePos2DRef.current[1]}, camera);
            let intersect = raycaster.intersectObject(planeRef.current);
            if (intersect.length > 0){
                let ii = intersect[0];

                
                m3DRef.current = averageRefRay();

                if (ii.point.y < -2){
                    ii.point.y = -2;
                }

                mousePos3DRef.current = {point: {x: ii.point.x, y: ii.point.y, z: ii.point.z} };
                updateRefRay(ii);
                //console.log(mousePos2DRef.current[1]);
            } 
        }
    }
    function applyNoiseTom3D(ref, time){
        
        ref.current[1] += 0.3 * Math.sin(time * 4);
    }
    function updateRawMouseToAverage(e){
        let ray = [];
        averageM3DRef.current.forEach((value) => {
            ray.push(value);
        })
        ray.push([e[0], e[1], e[2]]);
        ray.shift();
        averageM3DRef.current = ray;
    }

    function posToMesh(pRef, mRef, offsetRay){
        mRef.current.position.set(pRef.current.position.x - offsetRay[0], pRef.current.position.y - offsetRay[1], pRef.current.position.z - offsetRay[2])
    }

    useEffect(() => {

        initRefRay(20);
        lastMousePos2DRef.current = [0.0, 0.0]
        mousePos2DRef.current = [0.0,0.0];
        mousePos3DRef.current = {point: {x: 0, y: 0, z: 0}};
        mvRef.current = [0.0, 0.0, 0.0];
        m3DRef.current = [0.0,0.0,0.0];


    }, [])


    const [brakeState, setBrakeState] = useState(['no brakes', 0]);
    const [lastBrakeTime, setLastBrakeTime] = useState(0);


    const BRAKESCALE = 3.0;
    const MINBRAKETIME = 0.3;
    function applyBrakes(delta, time, BRAKESCALE){
        let estado = ['no brakes', 0]
        let pLength = vec3.length(AstonData.positionalDirection);
        let mLength = vec3.length(AstonData.massVelocity);
        
        if (time - lastBrakeTime < MINBRAKETIME){
            vec3.scale(AstonData.tmp, AstonData.massVelocity, delta * BRAKESCALE * -1.0);
            vec3.add(AstonData.massVelocity, AstonData.massVelocity, AstonData.tmp);
            estado = ['brake' , 1]
        } else if ( pLength / mLength < 20 && mLength > 0.2 /*||  < MINBRAKETIME */ ) {
            estado = ['brake', 1]

            // this is where we can add a reverse velocity to the vessel
            vec3.scale(AstonData.tmp, AstonData.massVelocity, delta * BRAKESCALE * -1.0);
            vec3.add(AstonData.massVelocity, AstonData.massVelocity, AstonData.tmp);
            setLastBrakeTime(time);
        } else {
            estado = ['no brakes', 0]
        }

        if (estado[1] != brakeState[1]){
            setBrakeState(estado);
            if (brakeState[1] === 1){
                setLastBrakeTime(time);
                //console.log(time);
            }
            
        }
        
    }
    useFrame((state, delta) => {
        //use below to force raycast every frame
        //updateMousePos(raycaster, planeRef);
        //console.log(props.hoveringDom);
        updateMousePosClient(raycaster, camera, planeRef);
        applyNoiseTom3D(m3DRef, state.clock.elapsedTime);
        //console.log(props.readPointer());
        setDataFromRefs(posRef, mvRef, m3DRef);
        updateMouseToVesselVector();
        updateNormalized();
        updateDistances();
        updateDots();
        applyThrust(delta, 0.01, 0.6, 0.7);
        applyBrakes(delta, state.clock.elapsedTime, BRAKESCALE);
        //debugRef.current = vec3.length(AstonData.positionalDirection) / vec3.length(AstonData.massVelocity)
        applyMaxVelocity(delta, 0.5);
        applyPhysics(delta);
        setRefsFromData(posRef, mvRef);

        props.setPosRef(posRef.current);
        //ROTATION
        updateRotations(rotationRef, posRef);
        //console.log(rotationRef);
    });
//rotation-x 0.33 , testing position={[0.0,20.0,-10.0]} but idk bout that
    return (
        <>
            
            <mesh rotation-x={-Math.PI * 0.33} ref={planeRef} >
                <planeGeometry args={[150,150]}/>
                <meshStandardMaterial visible={false} wireframe={true} color='blue'/>
            </mesh>

            {/*<Text fontSize={2}>{brakeState[0]}</Text>*/}

            <Vehicle_JSX test={2} ref={posRef}/>
            
        </>
    )
}

export default AstonFish

