import { createNoise3D, createNoise2D } from 'simplex-noise';
import {vec3, vec4} from 'gl-matrix';
const noise3d = createNoise3D();
const noise2d = createNoise2D();

const sampleParabola = (v, width, amp) => {
    return amp * ( Math.pow( Math.abs(v / width), 1 ) )
}

function lerp(v1, v2, t) {
  return (1 - t) * v1 + t * v2;
}

const sampleNoise = (x, y, z, iteration, width, amp, freq) => {//granular noise
    let scale = 1.0/10.0;
    let octaves = 100.0;
    let persistence = 0.6;
    let lacunarity = 1.5;


    let value = 0;

    for (let i = 0; i < octaves; i++){

      //value += amp * noise3d(( x + z) * freq * scale, y * freq * scale, 0);
      value += amp * noise2d(x * freq * scale, (y + iteration ) * freq * scale)

      amp *= persistence;
      freq *= lacunarity;

    }

    return value;
    
}

function clamp(num, min_value, max_value) {
  return Math.max(Math.min(num, max_value), min_value);
}
function shape(x) {
  if (x <= 0.5) {
    return x * x * 2;
  } else {
    return 1 - (1 - x) * (1 - x) * 2;
  }
}

const sampleNoise3 = (x, y, z, iteration, width, amp, freq) => {
  let scale = 1.0/2.0;
  let value = 0;
  value += amp *  clamp(noise2d(x * freq * scale, (y + iteration ) * freq * scale), 0.05, 0.4 )//> 0.1 ? 0.0 : 1.0 ;
  return value;
}

const applyGully = (x, amp, width, xOffset) => {
  let nOfx = Math.abs( xOffset + ( width / 2 ) - x)
  return amp * (nOfx * nOfx)
}


const zOfXYT = (x, y, t, width, height, iteration, amp1, amp2, freq1, freq2 ) => {
    //let v = sampleNoise2(x, y, t);
    let v = sampleNoise3(x, y, t, iteration, width, amp2, freq2);
    //let v = 1.0;
    v += sampleNoise(x, y, t, iteration, width, amp1, freq1)

    v += applyGully(x, 0.001, width, -15);
    return v //- sampleParabola(x, height, 20.0);
}

const colorOfXYZT = (x, y, z, t) => {

    return {
      r: z,
      g: z / 5,
      b: Math.sqrt(x**2 + y**2) / 75
    }
}


const calculateSurfaceTangent = (pRay, index, width, height) => {
  let position = vec3.fromValues(pRay[index], pRay[index+1], pRay[index+2]);

  let leftIndex = ( (index - 3) + pRay.length) % pRay.length;
  
  let leftVector = vec3.fromValues(pRay[leftIndex], pRay[leftIndex+1], pRay[leftIndex+2]);

  if (Math.abs(position[0] - leftVector[0]) > 50){
    leftVector[0] = position[0] - 1.0;
    leftVector[1] = position[1];
  }


  vec3.subtract(leftVector, position, leftVector);
  vec3.normalize(leftVector, leftVector);

  return leftVector;
  
}




const rowOfTangents = (pRay, width, height, indexStart) => {
  let tangents = [];
  for (let i = indexStart; i < indexStart + (width * 3); i+=3){
    let t = calculateSurfaceTangent(pRay, i, width, height);
    tangents.push(t[0],t[1],t[2])
  }
  return tangents;
}

const calculateSurfaceNormal = (pRay, index, width, height) => {//this also calculates tangent ???
  let position = vec3.fromValues(pRay[index], pRay[index+1], pRay[index+2]);
  
  let upIndex = ( index - (width * 3) + pRay.length ) % pRay.length;
  let leftIndex = ( (index - 3) + pRay.length) % pRay.length;
  let rightIndex =  (index + 3)  % pRay.length;
  let downIndex = (index + (width * 3)) % pRay.length;

  //let upVector = vec3.fromValues( ( pRay[upIndex] + width ) % width, ( pRay[upIndex+1] + width) % width , ( pRay[upIndex+2] + width) % width  );
  let upVector = vec3.fromValues( pRay[upIndex] , pRay[upIndex+1]  , pRay[upIndex+2]);
  let leftVector = vec3.fromValues(pRay[leftIndex], pRay[leftIndex+1], pRay[leftIndex+2] );
  let rightVector = vec3.fromValues(pRay[rightIndex], pRay[rightIndex+1], pRay[rightIndex+2] );
  let downVector = vec3.fromValues( pRay[downIndex], pRay[downIndex+1], pRay[downIndex+2] );


  //for edge cases, to make sure normals loop
  if ( Math.abs(position[1] - upVector[1]) > 50) {
    upVector[1] -= width;
  }

  if (Math.abs(position[1] - downVector[1]) > 50 ){
    downVector[1] += width;
  }


  vec3.subtract(upVector, position, upVector);
  vec3.normalize(upVector, upVector);

  vec3.subtract(leftVector, position, leftVector);
  vec3.normalize(leftVector, leftVector);

  vec3.subtract(downVector, position, downVector);
  vec3.normalize(downVector, downVector);

  vec3.subtract(rightVector, position, rightVector);
  vec3.normalize(rightVector, rightVector);

  
  //return [leftVector[0], leftVector[1], leftVector[2] ];
  let tmp = vec3.fromValues(-1,-1,-1)
  let normalone = vec3.create();
  vec3.cross(normalone, upVector, leftVector);
  vec3.multiply(normalone, normalone, tmp);

  let normaltwo = vec3.create();
  vec3.cross(normaltwo, downVector, rightVector);
  vec3.multiply(normaltwo, normaltwo, tmp);

  vec3.lerp(normalone, normalone, normaltwo, 0.5);

  //return [normalone[0] * -1, normalone[1] * -1, normalone[2] * -1]
  return [ normalone[0], normalone[1], normalone[2]];
}

function initTangents(pRay, width, height){
  let tangents = [];

  for (let i = 0; i < pRay.length; i+=3){
    let t = calculateSurfaceTangent(pRay, i, width, height);
    tangents.push(t[0], t[1], t[2]);
  }
  return tangents;

}

function initNormals(pRay, width, height){
  let normals = [];

  for ( let i = 0; i < pRay.length; i+=3){
    let n = calculateSurfaceNormal(pRay, i, width, height);
    normals.push(n[0],n[1],n[2]);

  }

  return normals;

}

function rowOfNormals(pRay, width, height, indexStart){
  let normals = [];
  for (let i = indexStart; i < indexStart + (width * 3); i+=3){
    let n = calculateSurfaceNormal(pRay, i, width, height);
    normals.push(n[0],n[1],n[2])
  }
  return normals;
}

export {
     zOfXYT, sampleParabola, colorOfXYZT, initNormals, rowOfNormals, rowOfTangents, initTangents
}
