import {vec3} from 'gl-matrix';

const AstonData = {
    mousePos3D: vec3.create(),
    vesselPos: vec3.create(),
    massVelocity: vec3.create(),
    massVelocityNormalized: vec3.create(),
    positionalDirection: vec3.create(),
    positionalDirectionNormalized: vec3.create(),
    tmp: vec3.create(),
    thrustVector: vec3.create(),
    distanceToMouse: 0.0,
    directionalDot: 0.0
}



function setDataFromRefs(posRef, mvRef, m3DRef){
    //posRef is ref to a mesh, mvRef is ref to a simple array, hence the difference
    AstonData.vesselPos.set([posRef.current.position.x, posRef.current.position.y, posRef.current.position.z]);
    AstonData.massVelocity.set([mvRef.current[0], mvRef.current[1], mvRef.current[2]]);
    AstonData.mousePos3D.set([m3DRef.current[0],m3DRef.current[1],m3DRef.current[2]]);

}

function updateMouseToVesselVector(){
    vec3.subtract(AstonData.positionalDirection, AstonData.mousePos3D, AstonData.vesselPos);
}

function updateNormalized(){
    vec3.normalize(AstonData.massVelocityNormalized, AstonData.massVelocity);
    //console.log(AstonData.massVelocity);
    vec3.normalize(AstonData.positionalDirectionNormalized, AstonData.positionalDirection);
}

function updateDistances(){
    AstonData.distanceToMouse = vec3.length(AstonData.positionalDirection);
}

function updateDots(){
    AstonData.directionalDot = vec3.dot(AstonData.massVelocityNormalized, AstonData.positionalDirectionNormalized);
}


const SCALE = 0.1;
const DRAGVALUE = -0.2;
const THRUSTSCALE = 1.0;
function applyDrag(delta){
    vec3.scale(AstonData.tmp, AstonData.massVelocity, SCALE * DRAGVALUE * delta);
    vec3.add(AstonData.massVelocity, AstonData.tmp, AstonData.massVelocity);

}



function applyThrustb(delta){
    vec3.scale(AstonData.tmp, AstonData.massVelocityNormalized, -1.0);
    console.log(AstonData.massVelocity);
    vec3.add(AstonData.tmp, AstonData.massVelocityNormalized, AstonData.positionalDirectionNormalized);
    console.log(AstonData.tmp);
    vec3.scale(AstonData.tmp, 0.5 * delta * THRUSTSCALE * SCALE * 1.0);//1.0 is usually distance to mouse
    console.log(AstonData.tmp);
    
    vec3.add(AstonData.massVelocity, AstonData.massVelocity, AstonData.tmp)
}



function applyThrust(delta, MAXTHRUST, turnScale, speedScale){
    //vec3.scale(AstonData.tmp, AstonData.positionalDirectionNormalized, delta * 0.01);
    //vec3.add(AstonData.massVelocity, AstonData.massVelocity, AstonData.tmp);
    vec3.scale(AstonData.tmp, AstonData.massVelocityNormalized, -1.0);
    vec3.scale(AstonData.tmp, AstonData.massVelocityNormalized, turnScale * -1.0);//scale this value for turn radius and bound default .7
    vec3.add(AstonData.thrustVector, AstonData.tmp, AstonData.positionalDirectionNormalized);

    vec3.scale(AstonData.tmp, AstonData.thrustVector, delta * AstonData.distanceToMouse * speedScale);//scale this for speed default .9

    if (vec3.length(AstonData.tmp) > 200){
        
        vec3.normalize(AstonData.tmp, AstonData.tmp);
        vec3.scale(AstonData.tmp, AstonData.tmp, MAXTHRUST);
    }
    vec3.add(AstonData.massVelocity, AstonData.tmp, AstonData.massVelocity);
    
}

function applyBrakes(delta, MAXBREAKDISTANCE, speedScale){
    // THRUSTDESIRE = (AstonData.directionDot > 0 ) * AstonData.directionDot * (AstonData.massVelocity.length / MAXTHRUST)
    //THRUSTDESIRE (AstonData.positionalDirection.length % AstonData.massVelocity.length)
    //textRef.current = vec3.length(AstonData.positionalDirection) / vec3.length(AstonData.massVelocity);
    //sconsole.log( vec3.length(AstonData.positionalDirection) / vec3.length(AstonData.massVelocity) )
}

function applyThrustOrbit(time){
    //vec3.set(AstonData.tmp, Math.sin(time), 0.0, 0.0);
    AstonData.tmp.set([1.0,1.0,0.0]);
    vec3.add(AstonData.massVelocity, AstonData.massVelocity, AstonData.tmp);
}

function applyPhysics(delta){
    vec3.add(AstonData.vesselPos, AstonData.vesselPos, AstonData.massVelocity);
}

function applyMaxVelocity(delta, maxVel){
    if (vec3.length(AstonData.massVelocity) > maxVel){
        vec3.normalize(AstonData.tmp, AstonData.massVelocity);
        vec3.scale(AstonData.massVelocity, AstonData.tmp, maxVel);
    }
}

function setRefsFromData(posRef, mvRef){
    posRef.current.position.set(AstonData.vesselPos[0], AstonData.vesselPos[1], AstonData.vesselPos[2]);
    mvRef.current = [AstonData.massVelocity[0], AstonData.massVelocity[1], AstonData.massVelocity[2]];

    posRef.current.positionalDirectionNormalized = AstonData.positionalDirectionNormalized;
    posRef.current.distanceToMouse = AstonData.distanceToMouse;
    posRef.current.directionalDot = AstonData.directionalDot;
}

export {AstonData, setDataFromRefs, updateNormalized, updateMouseToVesselVector,
    updateDots, updateDistances, applyDrag, applyThrust, applyThrustOrbit,
    applyPhysics, applyMaxVelocity, setRefsFromData}