import {useFrame, useLoader, useThree} from '@react-three/fiber';
import { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';

import vertexShader from './shader/vertexShader.glsl';
import fragmentShader from './shader/fragmentShader.glsl';
//import vertexV2 from './shader/vertexShaderV2.glsl';
//import fragmentV2 from './shader/fragmentShaderV2.glsl';

import {zOfXYT, colorOfXYZT, initNormals, rowOfNormals, initTangents, rowOfTangents} from './noise.js';

function OceanFloor({
    readPosRef,
    position,
    rotation,
    v2,
    grid: {
        width,
        height,
        sep
    },
    noise: {
        amp1,
        amp2,
        freq1,
        freq2
    }
}) {
    let t = 0;

    function position(){
        //return [position[0] - ( (width * sep) / 20), 0, position[1] - ( (height * sep) / 20)]
        return [40,-5,-86]
    }

    const meshRef = useRef(position());
    
    let noiseIteration = 0;

    let {positions, colors, normals, uvs, tangents } = useMemo(() => {
        let positions = [], colors = [], normals = [], uvs = [], tangents = []

        for (let yi = 0; yi < height; yi++){
            let y = sep * yi;
            if (yi === 0){
                //y -= sep;
            }
            for (let xi = 0; xi < width; xi++){
                let x = sep * xi;
                let z = zOfXYT( x, y, 1, width, height, noiseIteration, amp1, amp2, freq1, freq2 );
                positions.push(x, y, z  - 1);

                let color = colorOfXYZT(x, y, z, t);
                colors.push(color.r, color.g, color.b);
                //colors.push(1,1,1);
                normals.push(0, 0, 1);

                uvs.push (x % 1, y % 1);
            }
        }

        noiseIteration += width - 1
        normals = initNormals(positions,width,height);
        tangents = initTangents(positions, width, height);

        return {
            positions: new Float32Array(positions),
            colors: new Float32Array(colors),
            normals: new Float32Array(normals),
            uvs: new Float32Array(uvs),
            tangents: new Float32Array(tangents)
        }
    }, [width, height, sep, zOfXYT, colorOfXYZT, t ])

    let indices = useMemo(() => {
        let indices = []
        let i = 0;

        for (let yi = 0; yi < height -1; yi++){
            for (let xi = 0; xi < width - 1; xi++){
                indices.push(i, i + 1, i + width);
                //indices.push(i + width + 1, i + width, i);
                //indices.push(i, i + width, i + width + 1);
                indices.push(i + width, i + 1, i + width + 1);
                i++;
            }
            i++;
        }

        return new Uint16Array(indices);
    }, [width, height])

    const posRef = useRef(), colorRef = useRef(), normalRef = useRef(), tangentRef = useRef()
    const matRef = useRef();

    const uvMap = useLoader(THREE.TextureLoader, '/uv.png');

    const tex1_albedo = useLoader(THREE.TextureLoader, '/textures/RedRock/121_albedo.png');
    tex1_albedo.wrapT = THREE.RepeatWrapping
    //tex1_albedo.anisotropy = 2;

    const tex1_normal = useLoader(THREE.TextureLoader, '/textures/RedRock/121_normal.png');
    tex1_normal.wrapT = THREE.RepeatWrapping
    //tex1_normal.anisotropy = 2;
    const tex1_height = useLoader(THREE.TextureLoader, '/textures/RedRock/121_height.png');
    tex1_height.wrapT = THREE.RepeatWrapping
    
    //254 looks cool, so does 269
    const tex2_albedo = useLoader(THREE.TextureLoader, '/textures/WavySandy/254_albedo2.png');
    tex2_albedo.wrapT = THREE.RepeatWrapping
    const tex2_normal = useLoader(THREE.TextureLoader, '/textures/WavySandy/254_normal.png');
    tex2_normal.wrapT = THREE.RepeatWrapping
    const tex2_height = useLoader(THREE.TextureLoader, '/textures/WavySandy/254_height.png');
    tex2_height.wrapT = THREE.RepeatWrapping

    const noise_texture = useLoader(THREE.TextureLoader, '/textures/Noise/noise.png');
    const caustic_texture = useLoader(THREE.TextureLoader, v2 ? '/textures/Noise/caust29.png' : '/textures/Noise/caustic_new.png');


    const uniforms = useMemo(() => ({
        uTime: {
          value: 0.0
        },
        uT: {
            value: t
        },
        uAstonPos: {
            value: new THREE.Vector3(0,0,0)
        },
        uWidth: {
            value: width
        },
        uHeight: {
            value: height
        },
        uSep: {
            value: sep
        },
        uTimeMult: {
            value: 10
        },
        uUvMap: {
            value: uvMap
        },
        uTex1Albedo: {
            value: tex1_albedo
        },
        uTex1Normal: {
            value: tex1_normal
        },
        uText1Height: {
            value: tex1_height
        },
        uTileSize1: {
            value: 6.0
        },
        uTex2Albedo: {
            value: tex2_albedo
        },
        uTex2Normal: {
            value: tex2_normal
        },
        uTex2Height: {
            value: tex2_height
        },
        uNoise: {
            value: noise_texture
        },
        uCaustic: {
            value: caustic_texture
        }
      }), [])



    const {camera} = useThree();

    useEffect(() => {
        //console.log(normals);
        //console.log( tileOne )
        console.log(v2)

        const newArr = [];
        for (let i = 2; i < colors.length; i += 3) {
            newArr.push(colors[i]);
        }

        //console.log(`Min: ${Math.min(...newArr)}`);
        //console.log(`Max: ${Math.max(...newArr)}`);


        
        function handleResize(){
            let ratio = window.innerWidth / window.innerHeight;
            if (ratio < 0.84){
                meshRef.current.position.y = -9;
            } else {
                meshRef.current.position.y = -6;
            }
        }

        handleResize();

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);


    let posArray = []

    let lastTerrainUpdate = 0;
    let rate = 0.02;
    let start_index = width * 3 * 2;
    let start_nrm_index = 0
    useFrame((state) => {
        //console.log(state.clock.elapsedTime)
        matRef.current.uniforms.uTime.value = state.clock.elapsedTime % 12.0;
        
        if (state.clock.elapsedTime > lastTerrainUpdate + rate){
            
            posArray = readPosRef();
            posArray = new THREE.Vector3(posArray.x + 40 - ( width / 2), posArray.y - 6 , posArray.z - 86 - (width / 2));
            matRef.current.uniforms.uAstonPos.value = posArray;

            lastTerrainUpdate = state.clock.elapsedTime;
            t += 0.1;
            matRef.current.uniforms.uT.value = t;
            
            const positions = posRef.current.array, colors = colorRef.current.array, normals = normalRef.current.array, tangents = tangentRef.current.array

            //console.log(start_index - positions.length)
            //console.log(t);
            
            let newnormals = rowOfNormals(positions, width, height, start_nrm_index);
            let newtangents = rowOfTangents(positions, width, height, start_nrm_index);
            let ndex = 0;
            for (let i = start_nrm_index; i < start_nrm_index + width * 3; i+=3){
                normals[i] = newnormals[ndex];
                normals[i+1] = newnormals[ndex+1];
                normals[i+2] = newnormals[ndex+2];

                tangents[i] = newtangents[ndex];
                tangents[i+1] = newtangents[ndex+1];
                tangents[i+2] = newtangents[ndex+2];
                ndex += 3
            }

            for (let i = start_index; i < start_index + width * 3; i+=3){
                let lz = positions[i+2]
                //positions[i+2] = zOfXYT(positions[i] + t, positions[i+1], t, width, height);

                positions[i+2] = zOfXYT(positions[i] , positions[i+1], 1, width, height, noiseIteration, amp1, amp2, freq1, freq2 );
                
                
                let c = colorOfXYZT(positions[i] + t, positions[i+1] + t, t, width, height);
                colors[i] = c.r;
                colors[i+1] = c.g;
                colors[i+2] = c.b;

            }

            

            
            start_index += width * 3;
            start_nrm_index += width * 3;
            if (start_index + width * 3 > positions.length){
                start_index = 0;
                noiseIteration += width - 1;
            }
            if (start_nrm_index + width * 3 > positions.length){
                start_nrm_index = 0;
            }

            posRef.current.needsUpdate = true;
            colorRef.current.needsUpdate = true;
            normalRef.current.needsUpdate = true;
            tangentRef.current.needsUpdate = true;
        }
    })



    
    return (
        <mesh ref={meshRef} position={position()} rotation={rotation} frustumCulled={false}>
            <bufferGeometry>

                <bufferAttribute
                    ref={posRef}
                    attach={"attributes-position"}
                    array={positions}
                    count={position.length / 3}
                    itemSize={3}
                />

                <bufferAttribute
                    attach={"attributes-uv"}
                    array={uvs}
                    count={uvs.length / 2}
                    itemSize={2}
                />

                <bufferAttribute
                    ref={normalRef}
                    attach={"attributes-normal"}
                    array={normals}
                    count={normals.length / 3}
                    itemSize={3}
                />

                <bufferAttribute
                    ref={tangentRef}
                    attach={"attributes-tangent"}
                    array={tangents}
                    count={tangents.length / 3}
                    itemSize={3}
                />

                <bufferAttribute
                    ref={colorRef}
                    attach={"attributes-color"}
                    array={colors}
                    count={colors.length / 3}
                    itemSize={3}
                />
                <bufferAttribute
                    attach='index'
                    array={indices}
                    count={indices.length}
                    itemSize={3}
                />
            </bufferGeometry>

            {/*
            <meshStandardMaterial
                vertexColors={true}
                wireframe={true}
            />
            */}
            <shaderMaterial
            ref={matRef}
            depthWrite={false}
            fragmentShader={fragmentShader}
            vertexShader={vertexShader}
            uniforms={uniforms}
            alphaTest={true}
            transparent={true}
            
        />

        </mesh>
    )
}

export default OceanFloor
