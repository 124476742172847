import './App.css'
import { useState, useRef } from 'react'
import { Canvas } from '@react-three/fiber';
import {OrbitControls, Environment} from '@react-three/drei';
import {EffectComposer, Noise, Vignette, Bloom, ToneMapping, BrightnessContrast } from '@react-three/postprocessing';
import { Suspense } from "react";
import AstonFish from './components/AstonFish/AstonFishCustom2';//AstonFishCustom
import OceanParticles from './components/OceanParticles/OceanParticles3.jsx';
import OceanFloor from './components/OceanFloor/OceanFloorCustom';
import MiloDom from './components/MiloDom/MiloDom.jsx';
import CameraViewController from './components/CameraViewController.jsx'


function App() {
  const [v2, setV2] = useState(false);
  const pointerRef = useRef([0.0,0.0]);
  const AstonPosRef = useRef([0.0,0.0,0.0]);
  const [hoveringDom, setHoveringDom] = useState(false);
  function handleHoverDom(val){
    setHoveringDom(val);
  }
  
  function setPointer(array){
    pointerRef.current = array;
  }

  function readPointer(){
    return pointerRef.current;
  }

  function setPosRef(array){
    AstonPosRef.current = array.position;
  }

  function readPosRef(){
    return AstonPosRef.current;
  }
  
  

  const [menuActive, setMenuActive] = useState(true);
  const [aboutActive, setAboutActive] = useState(false);
  const [doneLoading, isDoneLoading] = useState( 0 );

  function handleDoneLoading(val){
    isDoneLoading(val);
    
  }

  function handleAboutActive(val){
    setAboutActive(val);

  }

  function handleMenuActive(val){
    setMenuActive(val);
    //console.log(val)
  }



  const [dpr, setDpr] = useState( window.devicePixelRatio * 0.8);
  const [noiseLevel, setNoiseLevel] = useState(0.02)//.03

  const dist = 0.7;//tiny mode 0.6, fov 120? medium is 0.7 fov 60, ultra wide is 1.0 fov 40

  //let cur_Dpr = dpr;

  function handleDpr(deepr){
    setDpr(deepr);
  }

  return (
    <div className="App">
      <div className='background-container'>
        <Canvas dpr={dpr} className='canvo' camera={{ fov: 60, position: [7 * dist, 6 * dist, 20 * dist]}}>
        
        
        <OrbitControls
          enablePan={true}
          enableRotate={true}
          enableZoom={true}
        />
          <Suspense fallback={null}>


          <CameraViewController/>
          
              <Environment files='/greenfog_low.hdr' background={true}/>
              <OceanParticles dpr={dpr}/>
              
              <AstonFish menuActive={menuActive}
              aboutActive={aboutActive}
              handleDoneLoading={handleDoneLoading}
              readPointer={readPointer}
              setPosRef={setPosRef}
              hoveringDom={hoveringDom}
              />

              <OceanFloor
                readPosRef={readPosRef}
                v2={v2}
                position={[0,0,0]}
                rotation={[-Math.PI/2, 0, Math.PI]}
                grid={{
                  width: 100,
                  height: 100,
                  sep: 1.0
                }}
                noise={{
                  amp1: 0.4,
                  amp2: 4,
                  freq1: 1,
                  freq2: 0.1
                }}
              />

          </Suspense>
          
          <ambientLight intensity={0.2}/>
          <directionalLight position={[-57, 57, -57]} color={[0.7, 0.7, 0.2]} />
          

          <EffectComposer>
            <Vignette eskil={false} offset={0.1} darkness={0.7}/>
            <Noise opacity={noiseLevel}/>
            
            
            <Bloom luminanceThreshold={0.3} luminanceSmoothing={0.5} height={400} mipmapBlur={0.3}/>
            {/*<BrightnessContrast brightness={-0.03} contrast={-0.05}/>*/}
              
              <ToneMapping averageLuminance={0.5} adaptiveLuminanceMaterial={2} middleGrey={3}/>
              
              
              
              
              
              
          </EffectComposer>
        </Canvas>
      </div>
      
      <MiloDom menuActive={menuActive}
      setMenuActive={handleMenuActive}
      aboutActive={aboutActive}
      setAboutActive={handleAboutActive}
      doneLoading={doneLoading}
      setPointer={setPointer}
      readPointer={readPointer}
      hoveringDom={hoveringDom}
      handleHoverDom={handleHoverDom}
      />

    </div>
  )
}

export default App
