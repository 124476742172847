import { Float } from '@react-three/drei';
import {vec3, vec4} from 'gl-matrix';
//import {useRef} from 'react';

import * as THREE from 'three';

function initRotationRef( bodyLength, finLength, rudderLength){
    const x = vec3.create();
    vec3.set(x, 1,0,0);
    
    const y = vec3.create();
    vec3.set(y, 0,1,0);

    const z = vec3.create();
    vec3.set(z, 0,0,1);

    let bodyX = [];
    let bodyY = [];
    let bodyZ = [];

    for (let i = 0; i < bodyLength; i++){
      bodyX.push(0);
      bodyY.push(0);
      bodyZ.push(0);
    }

    let fin = [];
    for (let i = 0; i < finLength; i++){
      fin.push(0);
    }
    let rudder = [];
    for (let i = 0; i < rudderLength; i++){
      rudder.push(0);
    }

    return [
      [0, bodyX, 0, x],//index, values, average, vector for dot calculation
      [0, bodyY, 0, y],
      [0, bodyZ, 0, z],
      [0, fin, 0, x],
      [0, rudder, 0, x]
    ]
  }

function mousePosRotationAlgorithm(ref, v, scale){
    let pD = vec3.fromValues(ref.current.positionalDirectionNormalized[0], ref.current.positionalDirectionNormalized[1], ref.current.positionalDirectionNormalized[2]);
    
    return scale * vec3.dot(pD, v) * ref.current.distanceToMouse ;
}




function updateRotations(rotationRef, ref){
  /*

  for (let i = 0; i < rotationRef.length; i++){
    console.log(rotationRef[i][1].length);
    //rotationRef[i][1][rotationRef[i][0] % rotationRef[i][1].length] = mousePosRotationAlgorithm(ref, rotationRef[i][3], 1.0);
    //rotationRef[i][2] = mousePosRotationAlgorithm(ref, rotationRef[i][3], 1.0);
    rotationRef[i][2] = averageArray(rotationRef[i][1])
    rotationRef[i][0] += 1;
    console.log( rotationRef[i][2] );
  }
*/

      rotationRef.current.forEach((e) => {
          e[1][e[0] % e[1].length] = mousePosRotationAlgorithm(ref, e[3], 1.0);
          //e[1][e[0] % e[1].length] = 23.0;
          e[2] = averageArray(e[1]);//this is what sets rotation data
          //console.log(e[2])
          e[0] += 1;
      })

      ref.current.rotations = {
        bodyX: rotationRef.current[0][2],
        bodyY: rotationRef.current[1][2],
        bodyZ: rotationRef.current[2][2],
        fin: rotationRef.current[3][2],
        rudder: rotationRef.current[4][2]
      }


}

function averageArray(ray){
    let a = 0;
    for (let i = 0; i < ray.length; i++){
        a += ray[i];
    }
    a /= ray.length;
    return a;
}

export {updateRotations, initRotationRef}