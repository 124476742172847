import './MiloDom.css';
import {FaHamburger} from 'react-icons/fa';
import {RxCross2} from 'react-icons/rx'
import { useState } from 'react';
import { useEffect} from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import BarLoader from 'react-spinners/BarLoader';
export default function MiloDom(props) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [contactOpen, setContactOpen] = useState(false);
    const [emailClick, setEmailClick] = useState(false);
    const [localHover, setLocalHover] = useState(false);

    useEffect(() => {
        props.handleHoverDom(localHover);
    }, [localHover])


    function handleEmailClick() {
        navigator.clipboard.writeText('milo.arnerich@gmail.com')
        setEmailClick(true);
        setTimeout(function(){
            setEmailClick(false);
        }, 2000)
    }


    function handleMenuClick(){
        setMenuOpen(!menuOpen);
        //props.setMenuActive(menuOpen);
    }

    function handleAboutOpen(){
        setContactOpen(true);
        setIsContact(false);
        setMenuOpen(false)
        props.setAboutActive(true);
    }

    function handleContactOpen(){
        setContactOpen(true);
        setIsContact(true);
        setMenuOpen(false);
        props.setAboutActive(true);
    }

    function handleTextClose(){
        setContactOpen(false);

        props.setAboutActive(false);
        
    }

    function handleTouchMove(event){
        event.stopPropogation();
    }
    
    function handlePointerMove(event){
        event.preventDefault();
        let x = ( (event.clientX / window.innerWidth) * 2.0) - 1;
        let y = ( (event.clientY / window.innerHeight) * -2.0) + 1;
        props.setPointer([x, y]);
    }

    function handlePointerStartEnd(event){
        
        let x = ( (event.clientX / window.innerWidth) * 2.0) - 1;
        let y = ( (event.clientY / window.innerHeight) * -2.0) + 1;
        props.setPointer([x, y]);
    }


    const [isContact, setIsContact] = useState(false);
    
    return (
        <div className={props.doneLoading === 2 ? 'dom-elements-clear' : props.doneLoading === 0 ? 'dom-elements-black' : 'dom-elements-white' }
        
        onMouseMove={handlePointerMove}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchMove}
        onTouchEnd={handleTouchMove}
        >
            { !props.doneLoading ? //!props.doneLoading
                <div className='loading-canvas'>
                    <BarLoader color={'#ffffff'} loading={props.doneLoading === 0} className='loading-bar'/>
                    <p className='loading-text'>Loading...</p>
                </div> : ''
            }


            <div className='grid-container'>


                
                <div className='nav-menu'>
                    <div className={!contactOpen ? 'show-burger' : 'burger-hidden'}>
                        <div className='burger' onClick={handleMenuClick}>
                                {menuOpen ? <RxCross2 size={40} className='symbol'/> : <FaHamburger size={40} className='symbol'/> }
                        </div>

                            <p className={menuOpen ? 'menu-active' : 'menu-inactive'} onClick={handleAboutOpen} >About</p>
                            {/*
                            <p className={menuOpen ? 'menu-active' : 'menu-inactive'}>Shaders</p>
                            <p className={menuOpen ? 'menu-active' : 'menu-inactive'}>Reel</p>
                            */}
                            <p className={menuOpen ? 'menu-active' : 'menu-inactive'}
                            onClick={handleContactOpen}
                            >Contact</p>

                        </div>
                    </div>

                        
                            <div className={!contactOpen ? 'contact-card-closed' : 'contact-card-open'}>
                                <div className='contact-header'>
                                    <RxCross2 size={30} onClick={handleTextClose} className='symbol'/>
                                </div>

                                {isContact ? 
                                    <div className='email-contact'>
                                        <h4>email me at:</h4>
                                        <h4 className='my-email' onClick={handleEmailClick}>milo.arnerich@gmail.com</h4>
                                        <h4 className={emailClick ? 'copied-show' : 'copied-hide'}>copied to clipboard!</h4>
                                    </div>
                                     : 
                                     <div>
                                     <h4 className='about-text'>Hello, my name is Milo Arnerich, I am a technical artist and web developer, based in NYC.<br/>
                                     <br/>I specialize in 3d for the web!<br/>
                                     The interactive demo on this page shows my current interests and skills: <br/><br/>The submersible was modelled and rigged in Blender,
                                      and animated in Javascript and React using three.js/react-three-fiber. 
                                      <br/><br/>The floating particles were implemented through the use of a custom GLSL shader.
                                         <br/><br/>The final fragment shader for the ocean floor is achieved through the blending of three textures;
                                          a sand texture, a rock texture, and a caustic lighting texture, all of which were created with Stable Diffusion.
                                           <br/><br/>
                                           You can contact me at:
                                        </h4>
                                        
                                        <div className='email-contact'>
                                            <h4 className='my-email' onClick={handleEmailClick}>milo.arnerich@gmail.com</h4>
                                            <h4 className={emailClick ? 'copied-show' : 'copied-hide'}>copied to clipboard!<br/><br/> </h4>
                                            <h4 className='copied-hide'>blah blah blah hidden</h4>
                                        </div>
                                    </div>
                                    
                                }
                                
                            
                            </div> 
                

                <div className='blank2'></div>
                <div className='blank3'></div>


                {

                    !contactOpen ? 
                    <div className='name-title'>
                        <h3>Milo Arnerich</h3>
                        <h2>Technical Artist</h2>
                    </div> :
                    <>
                    </>
                }


            </div>

            {/*
            <div className="contact-form">
                <div className='contact-header'>
                    <h1>Contact</h1>
                    <RxCross2 size={30}/>
                </div>
                <form onSubmit={onSubmit}>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="name">
                            Name
                        </label>
                        <input className="form-control" type="text" id="name" required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="email">
                            Email
                        </label>
                        <input className="form-control" type="email" id="email" required />
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="message">
                            Message
                        </label>
                        <textarea className="form-control" id="message" required />
                    </div>
                    <button className="btn btn-danger" type="submit">
                        {formStatus}
                    </button>
                </form>
            </div>
    */}
        </div>
        
        
    )
}